import React, { useEffect, useState } from 'react'

import {
	Col,
	notification,
	Popconfirm,
	Row,
	Select,
	Space,
	Spin,
	Table,
} from 'antd'
import { Link } from 'react-router-dom'
import { Button, Container } from 'reactstrap'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import {
	deletePageBanner,
	getPageBanners,
} from '../../helpers/helper'
const { Column } = Table
const { Option } = Select
const dummy = {
	_id: 'fytiurytirytie',
	title: 'title',
	status: 'visible',
	index: 1,
	thumb: 'https://via.placeholder.com/150',
}

const pageLocationEnum = [
	{ key: 0, label: 'Trang chủ', value: 'home' },
	{ key: 1, label: 'Dịch vụ cho thuê VPS', value: 'vps' },
	{ key: 2, label: 'Dịch vụ Hosting, web hosting', value: 'hosting' },
	{ key: 3, label: 'Thuê Server, máy chủ vật lý', value: 'server' },
	{ key: 4, label: 'Đăng ký tên miền', value: 'domain' },
	{ key: 5, label: 'Thiết kế website', value: 'design' },
]

const PageBanner = () => {
	const [isLoading, setIsLoading] = useState(false)

	const [banners, setBanners] = useState([])
	const [page, setPage] = useState(1)
	const [limit, setLimit] = useState(10)
	const [results, setResults] = useState(0)

	const authUser = JSON.parse(localStorage.getItem('authUser'))

	const getBanners = async (limit, page) => {
		const result = await getPageBanners(limit, page)
		if (result) {
			setBanners(result.data)
			setResults(result.results)
		}
	}

	const confirm = (post) => {
		deletePageBanner(post._id)
			.then((res) => {
				notification['success']({
					message: 'Notification',
					description: 'Delete banner successfully!',
				})
				getBanners(limit, page)
			})
			.catch((error) => {
				notification['error']({
					message: 'System error',
					description: error,
				})
			})
	}

	//* Async Functions

	useEffect(() => {
		getBanners(limit, page)
	}, [page, limit])

	return (
		<React.Fragment>
			<Spin spinning={isLoading}>
				<div className='page-content'>
					<Container fluid>
						<BreadCrumb
							title='Page Banner'
							pageTitle='Quản lý Slide banners trang chủ'
						/>

						<Row>
							<Col
								style={{ width: '200px' }}
								lg='6'
							>
								<Button>
									<Link
										to={{
											pathname: '/page-banner/create/',
											// state: { id: val._id },
										}}
										style={{ color: '#fff' }}
									>
										Thêm mới banner
									</Link>
								</Button>
							</Col>
						</Row>

						{/* Table */}
						<Row>
							<Col lg={24}></Col>
							<Table
								rowKey='_id'
								dataSource={banners}
							>
								<Column
									title='#'
									render={(val, rec, index) => {
										return index + 1
									}}
								/>
								<Column
									title='Tiêu đề'
									dataIndex='title'
									key='title'
								/>
								<Column
									title='Trang đặt Banner'
									dataIndex='pageLocation'
									key='pageLocation'
									render={(pageLocation) => {
										const rs = pageLocationEnum.find(
											(item) => item.value === pageLocation
										)
										return rs.label
									}}
								/>
								<Column
									title='Đường dẫn trang đích'
									dataIndex='destinationHref'
									key='destinationHref'
									render={(target) => {
										if (!target) return 'Không có dữ liệu'
										else return target
									}}
								/>
								<Column
									title='Kiểu mở trang đích'
									dataIndex='destinationTarget'
									key='destinationTarget'
									render={(target) => {
										if (target === '_blank') return 'Mở tab mới'
										else return 'Mở trong tab hiện tại'
									}}
								/>
								<Column
									title='Hình ảnh Desktop'
									dataIndex='imgDeskSrc'
									key='imgDeskSrc'
									width={'180px'}
									render={(thumbnail) => {
										if (!thumbnail) return <p>Không có dữ liệu</p>
										return (
											<img
												src={thumbnail}
												alt={thumbnail.alt}
												style={{ width: '100%' }}
											/>
										)
									}}
								/>
								<Column
									title='Hình ảnh Tablet'
									dataIndex='imgTabSrc'
									key='imgTabSrc'
									width={'180px'}
									render={(thumbnail) => {
										if (!thumbnail) return <p>Không có dữ liệu</p>
										return (
											<img
												src={thumbnail}
												alt={thumbnail.alt}
												style={{ width: '100%' }}
											/>
										)
									}}
								/>
								<Column
									title='Hình ảnh Mobile'
									dataIndex='imgMobSrc'
									key='imgMobSrc'
									width={'180px'}
									render={(thumbnail) => {
										if (!thumbnail) return <p>Không có dữ liệu</p>
										return (
											<img
												src={thumbnail}
												alt={thumbnail.alt}
												style={{ width: '100%' }}
											/>
										)
									}}
								/>
								<Column
									title='Mô tả hình ảnh'
									dataIndex='imgAlt'
									key='imgAlt'
								/>
								<Column
									title='Trạng thái'
									dataIndex='isActive'
									key='isActive'
									render={(item) => {
										let rs = ''
										if (item === true) {
											rs = 'Đang hoạt động'
										}
										if (item === false) {
											rs = 'Không hoạt động'
										}
										return <>{rs}</>
									}}
								/>
								<Column
									title='Hoạt động'
									key='action'
									render={(val, record) => (
										<Space size='middle'>
											<Link
												to={{
													pathname: '/page-banner/edit/' + val._id,
													// state: { id: val._id },
												}}
											>
												<i className='ri-pencil-line action-icon'></i>
											</Link>
											<Popconfirm
												title='Are you sure to delete this post?'
												onConfirm={() => confirm(val)}
												okText='Yes'
												cancelText='No'
											>
												<i className='ri-delete-bin-line action-icon'></i>
											</Popconfirm>
										</Space>
									)}
								/>
							</Table>
						</Row>
					</Container>
				</div>
			</Spin>
		</React.Fragment>
	)
}

export default PageBanner
