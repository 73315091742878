module.exports = {
  google: {
    API_KEY: '',
    CLIENT_ID: '',
    SECRET: '',
  },
  facebook: {
    APP_ID: '',
  },
  api: {
    API_URL_CLIENT: 'http://localhost:8002',
    // API_URL: 'http://localhost:8000',
    API_URL: "https://api.gofiber.vn",
    API_URL_BUNNY: 'https://sg.storage.bunnycdn.com/baovietnam',
    ACCESS_KEY_BUNNY: 'f9e3afdd-1370-42a8-924150b950c1-eab4-4ecf',
  },
}
