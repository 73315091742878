import {
  Pagination,
  Popconfirm,
  Popover,
  Select,
  Spin,
  Table,
  notification,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BsFillTrash3Fill, BsJournalCheck } from "react-icons/bs";
import { Button, Col, Container, Input, InputGroup, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {
  changeStatusContact,
  deleteContactClient,
  getAllDocContactClient,
} from "../../helpers/helper";

const { Option } = Select;

const { Column } = Table;

function ContactManager() {
  const [searchInput, setSearchInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [seo, setSeo] = useState([]);

  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalPageSize, setTotalPageSize] = useState(0);

  const getContactClient = async (pageSize, pageIndex, searchInput) => {
    try {
      setIsLoading(true);
      const result = await getAllDocContactClient(
        pageSize,
        pageIndex,
        searchInput
      );
      if (result.statusCode === 1) {
        setSeo(result?.result || []);
        setTotalPageSize(result?.totalDocs || 0);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getContactClient(pageSize, pageIndex, searchInput);
  }, [pageSize, pageIndex, searchInput]);

  const searchSeo = () => {
    getContactClient(pageSize, pageIndex, searchInput);
  };

  const changeStatus = (record) => {
    const updateStatus = async (status) => {
      try {
        const result = await changeStatusContact(record?._id, status);
        if (result.statusCode === 1) {
          notification.success({
            message: "thay đổi trạng thái phản hồi thành công!",
          });
          getContactClient(pageSize, pageIndex, searchInput);
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    return (
      <div>
        <div
          onClick={() => {
            updateStatus(1);
          }}
          className="cursor-pointer"
        >
          Đã xem
        </div>
        <hr />
        <div
          onClick={() => {
            updateStatus(2);
          }}
          className="cursor-pointer"
        >
          Đã liên hệ khách hàng
        </div>
      </div>
    );
  };

  const confirm = async (item) => {
    try {
      const result = await deleteContactClient(item?._id);
      if (result.statusCode === 1) {
        notification.success({ message: "Xóa liên hệ thành công!" });
        getContactClient(pageSize, pageIndex, searchInput);
      }
    } catch (error) {
      console.log("error", error);
      notification.success({ message: "Xóa liên hệ thất bại!" });
    }
  };

  const cancel = (e) => {};

  return (
    <React.Fragment>
      <Spin spinning={isLoading}>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb
              title="Quản lý liên hệ từ khách hàng"
              pageTitle="Quản lý liên hệ từ khách hàng"
            />

            <Row className="mb-3">
              <Col lg="2">
                <div>
                  <InputGroup>
                    <Input
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                      placeholder="Nhập tên hoặc email..."
                    />
                  </InputGroup>
                </div>
              </Col>
              <Col lg="6">
                <Row>
                  <Col
                    style={{ width: "130px" }}
                    lg="6"
                  >
                    <div>
                      <Button onClick={() => searchSeo()}>Tìm kiếm</Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <Table
                  rowKey="_id"
                  dataSource={seo}
                  pagination={false}
                >
                  <Column
                    title="#"
                    width="80px"
                    render={(val, rec, index) => {
                      return index + 1;
                    }}
                  />
                  <Column
                    title="Họ và Tên"
                    dataIndex="clientName"
                    key="clientName"
                    width="200px"
                  />

                  <Column
                    title="Số điện thoại"
                    dataIndex="clientPhoneNumber"
                    key="clientPhoneNumber"
                    width="150px"
                    render={(item) => `${item}`}
                  />
                  <Column
                    title="Email"
                    dataIndex="clientEmail"
                    key="clientEmail"
                    width="250px"
                  />
                  <Column
                    title="Cần tư vấn về"
                    dataIndex="needAdvise"
                    key="needAdvise"
                    width="300px"
                    render={(item) => <div>{item.join(", ")}</div>}
                  />
                  <Column
                    title="Ngày gửi yêu cầu"
                    dataIndex="createdAt"
                    key="createdAt"
                    width="150px"
                    render={(item) => moment(item).format("DD-MM-YYYY")}
                  />
                  <Column
                    title="Trạng thái phản hồi"
                    dataIndex="status"
                    key="status"
                    width="250px"
                    render={(item) => {
                      return !item || item === 0 ? (
                        <div>
                          <span
                            style={{
                              backgroundColor: "#fff2e8",
                              padding: "5px 10px",
                              borderRadius: "8px",
                              color: "#d7471e",
                              fontWeight: "normal",
                              border: "1px solid #d7471e",
                            }}
                          >
                            Chưa xem
                          </span>
                        </div>
                      ) : item === 1 ? (
                        <div>
                          <span
                            style={{
                              backgroundColor: "#ffefd4",
                              padding: "5px 10px",
                              borderRadius: "8px",
                              color: "#ff7f00",
                              fontWeight: "normal",
                              border: "1px solid #ff7f00",
                            }}
                          >
                            Đã xem
                          </span>
                        </div>
                      ) : (
                        <div>
                          <span
                            style={{
                              backgroundColor: "#f6ffed",
                              padding: "5px 10px",
                              borderRadius: "8px",
                              color: "#58af34",
                              fontWeight: "normal",
                              border: "1px solid #58af34",
                            }}
                          >
                            Đã liên hệ
                          </span>
                        </div>
                      );
                    }}
                  />
                  <Column
                    title="Hành động"
                    dataIndex="actions"
                    key="actions"
                    render={(item, record) => {
                      return (
                        <div className="d-flex gap-2">
                          <Popconfirm
                            title={
                              <div>
                                <div className="font-bold">Xóa hồ sơ</div>
                                <div>
                                  Khi xóa hồ sơ các tệp đính kèm sẽ bị xóa, Bạn
                                  đồng ý xóa?
                                </div>
                              </div>
                            }
                            onConfirm={() => confirm(record)}
                            onCancel={cancel}
                            okText="Đồng ý"
                            cancelText="Hủy"
                            className="my-auto"
                          >
                            <BsFillTrash3Fill
                              size={20}
                              className="cursor-pointer"
                              style={{ color: "gray" }}
                            />
                          </Popconfirm>
                          <div className="my-auto cursor-pointer">
                            <Popover
                              content={() => changeStatus(record)}
                              trigger="click"
                            >
                              <BsJournalCheck size={20} />
                            </Popover>
                          </div>
                        </div>
                      );
                    }}
                  />
                </Table>
                <div className="text-right">
                  <Pagination
                    className="mt-4"
                    pageSize={pageSize}
                    onChange={(page, pageSize) => {
                      setPageIndex(page !== 0 ? page : 1);
                      setPageSize(pageSize);
                    }}
                    showTotal={(total) => `Tổng ${total} hồ sơ`}
                    total={totalPageSize}
                    showSizeChanger
                    defaultCurrent={1}
                    current={pageIndex}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Spin>
    </React.Fragment>
  );
}

export default ContactManager;
