//permission

export const GET_ALL_PERMISSION = '/api/permission/getPermissions'

//Roles
export const GET_ALL_ROLES = '/api/roles/getRoles'
export const EDIT_ROLE_PERMISSION = '/api/role/editRolePermission'

export const ADD_NEW_ROLES = '/api/roles/addNewRole'
export const DELETE_ROLES = '/api/roles/deleteRole'
export const UPDATE_ROLES = '/api/role/editRole'

//REGISTER
export const POST_FAKE_REGISTER = '/auth/signup'

//LOGIN
export const POST_FAKE_LOGIN = '/auth/signin'
export const POST_FAKE_JWT_LOGIN = '/post-jwt-login'
export const POST_FAKE_PASSWORD_FORGET = '/auth/forgot-password'
export const POST_FAKE_JWT_PASSWORD_FORGET = '/jwt-forget-pwd'
export const SOCIAL_LOGIN = '/social-login'
export const API_USER_LOGIN = '/api/user/login'

//login
export const POST_AUTHENTICATE = '/api/users/authenticate'

//BUNNY
export const URL_IMAGE_BUNNY = 'https://agency88.b-cdn.net/'

//PROFILE
export const POST_EDIT_JWT_PROFILE = '/post-jwt-profile'
export const POST_EDIT_PROFILE = '/api/user'

//user
export const GET_USERS = '/api/users'
// export const ADD_USERS = "/signup";
// export const DELETE_USER = "/api/users/delete";
export const SEARCH_USER = '/api/users/user/search'

export const GET_USER_PERMISSION = '/api/users/user/permission'
// faqs
export const GET_FAQS = '/api/faqs'

export const GET_POSTS = '/api/posts/getPaging'
export const GET_POST_BY_SLUG = '/api/posts'
export const GET_POST_BY_ID = '/api/posts/getById'
export const GET_SCHEMAS = '/api/schema'
export const GET_TAXONOMYS = '/api/taxonomy'
export const GET_TAGS = '/api/tag'
export const GET_PAGES = '/api/pages'
// export const GET_CATE = "/api/tag";

export const GET_TAXANOMY = '/api/taxanomy'

//Links
export const GET_LINKS = '/api/linkFooters'

//Google index
export const GOOGLEINDEX = 'api/google/index' //param
export const GOOGLEBATCHINDEX = 'api/google/batchIndex' // body

//Banner
export const BANNER = 'api/banners'

//Bing index
export const BINGINDEX = 'api/bing/index'

//Google Analytics
export const GGANALYTICS = 'api/ggAnalytics'

//Action
export const API_ACTION_INSERT = '/api/action/insert'
export const API_ACTION_UPDATE = '/api/action/update'
export const API_ACTION_DELETE = '/api/action/delete'
export const API_ACTION_GETALL = '/api/action/getAll'
export const API_ACTION_GET_PAGING = '/api/action/getPaging'
export const API_ACTION_GET_PAGING_BY_ID = '/api/action/getById'

//POSTS
export const API_POST_INSERT = '/api/posts'
export const API_POST_UPDATE = '/api/posts/update'
export const API_POST_DELETE = '/api/post/delete'

//Category
export const GET_CATES = '/api/category'
export const API_CATE = '/api/category'

//redirect
export const API_REDIRECT = '/api/redirect'
export const API_AUTOLINK = '/api/autolink'

//domains
export const API_DOMAINS = '/api/domains'
//tag
export const API_TAGS = '/api/tag'

//fp
export const API_FP = '/api/fp'

//
export const API_BlackList = '/api/blacklist'
export const API_WhiteList = '/api/whitelist'

//recruit
export const API_RECRUIT = '/api/recruits'

//Vps-tab
export const GET_VPS_TAB = '/api/vpsTab'

//Vps-tab
export const GET_VPS = '/api/vps'

//Hosting
export const GET_HOSTING = '/api/hosting'
export const GET_HOSTING_V2 = '/api/hostingV2'

//seo
export const API_SEO = '/api/seo'

//department
export const API_DEPARTMENT = '/api/departments'

//home slide banners
export const API_HOME_SLIDE_BANNER = '/api/banners'

// advertising banner
export const API_PAGE_BANNER = '/api/pageBanners'

// discount
export const API_DISCOUNT = '/api/discountByMo'

export const API_VPS_V2 = '/api/vpsV2'
