import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
	createHomeSlideBanner,
	createPageBanner,
	getHomeSlideBanner,
	getHomeSlideBanners,
	getPageBanner,
	updatePageBanner,
} from '../../helpers/helper'
import {
	Button,
	Checkbox,
	Col,
	Form,
	message,
	notification,
	Row,
	Select,
	Spin,
} from 'antd'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import { FormGroup, Input, Label } from 'reactstrap'
import {
	getListImageBunny,
	uploadFileToBunny,
} from '../../helpers/api_bunny'
import { MdOutlineEdit } from 'react-icons/md'
import { error } from '../../Components/Common/message'
const { Option } = Select
// * initial data
const initialData = {
	_id: '',
	title: '',
	userId: '',
	pageLocation: '',
	destinationHref: '',
	destinationTarget: '',
	imgDeskSrc: '',
	imgTabSrc: '',
	imgMobSrc: '',
	imgAlt: '',
	isActive: false,
	createdAt: '',
	updatedAt: '',
}
// enum: ['home', 'vps', 'hosting', 'server', 'domain', 'design'],
const pageLocationEnum = [
	{ key: 0, label: 'Trang chủ', value: 'home' },
	{ key: 1, label: 'Dịch vụ cho thuê VPS', value: 'vps' },
	{ key: 2, label: 'Dịch vụ Hosting, web hosting', value: 'hosting' },
	{ key: 3, label: 'Thuê Server, máy chủ vật lý', value: 'server' },
	{ key: 4, label: 'Đăng ký tên miền', value: 'domain' },
	{ key: 5, label: 'Thiết kế website', value: 'design' },
]

const CreateEditBanner = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [banner, setBanner] = useState(initialData)
	const [formAdd, setFormAdd] = useState(initialData)
	const [isModalChooseImage, setIsModalChooseImage] = useState(false)
	const [imageChoose, setImageChoose] = useState()
	const [mediaList, setMediaList] = useState([])
	const [isModalEditImage, setIsModalEditImage] = useState(false)

	const { id } = useParams()
	const history = useHistory()
	const getBanner = async (id) => {
		const result = await getPageBanner(id)
		if (result) {
			setBanner(result.data)
			setFormAdd(result.data)
		}
	}

	//* form control
	const onSave = async () => {
		setIsLoading(true)
		const data = {
			type: 'post',
			title: formAdd?.title,
			pageLocation: formAdd?.pageLocation,
			destinationHref: formAdd?.destinationHref,
			destinationTarget: formAdd?.destinationTarget,
			imgDeskSrc: formAdd?.imgDeskSrc,
			imgTabSrc: formAdd?.imgTabSrc,
			imgMobSrc: formAdd?.imgMobSrc,
			imgAlt: formAdd?.imgAlt,
			isActive: formAdd?.isActive,
		}

		if (formAdd._id !== '') {
			// update
			updatePageBanner(formAdd._id, data)
				.then((res) => {
					if (res.success === true) {
						notification['success']({
							message: 'Notification',
							description: 'Create Banner successfully!',
						})
						message.success('Lưu thành công')
					}

					setIsLoading(false)
					history.push('/page-banner')
				})
				.catch((error) => {
					setIsLoading(false)
					notification['error']({
						message: 'System error',
						description: error,
					})
				})
		} else if (id) {
			// update
			updatePageBanner(id, data)
				.then((res) => {
					if (res.success === true) {
						notification['success']({
							message: 'Notification',
							description: 'Create post successfully!',
						})
						message.success('Lưu thành công')
					}

					setIsLoading(false)
					history.push('/page-banner')
				})
				.catch((error) => {
					setIsLoading(false)
					notification['error']({
						message: 'System error',
						description: error,
					})
				})
		} else {
			//Lưu
			// return;
			setIsLoading(true)
			createPageBanner(data)
				.then((res) => {
					if (res.status === -2) {
						throw new Error(res.error)
					}
					setIsLoading(false)
					notification['success']({
						message: 'Notification',
						description: 'Create post successfully!',
					})
					history.push('/page-banner')
				})
				.catch((error) => {
					setIsLoading(false)
					notification['error']({
						message: 'System error',
						description: error,
					})
				})
		}
	}
	const onBack = () => {
		history.goBack()
	}
	const onInputChange = async (e) => {
		formAdd[e.target.name] = e.target.value
		setFormAdd(formAdd)

		if (e.target.name === 'imgDeskSrc') {
			let file = e.target.files ? e.target.files[0] : null

			if (file) {
				let fileName = file?.name?.replaceAll(' ', '-')
				uploadFileToBunny(file).then((res) => {
					if (res.HttpCode === 201) {
						setFormAdd({
							...formAdd,
							imgDeskSrc:
								'https://gofiber.b-cdn.net/Admin/' + fileName,
						})
					}
				})
			}
		}

		if (e.target.name === 'imgTabSrc') {
			let file = e.target.files ? e.target.files[0] : null

			if (file) {
				let fileName = file?.name?.replaceAll(' ', '-')
				uploadFileToBunny(file).then((res) => {
					if (res.HttpCode === 201) {
						setFormAdd({
							...formAdd,
							imgTabSrc:
								'https://gofiber.b-cdn.net/Admin/' + fileName,
						})
					}
				})
			}
		}

		if (e.target.name === 'imgMobSrc') {
			let file = e.target.files ? e.target.files[0] : null

			if (file) {
				let fileName = file?.name?.replaceAll(' ', '-')
				uploadFileToBunny(file).then((res) => {
					if (res.HttpCode === 201) {
						setFormAdd({
							...formAdd,
							imgMobSrc:
								'https://gofiber.b-cdn.net/Admin/' + fileName,
						})
					}
				})
			}
		}
	}
	const onChangePageLocation = (value) => {
		setFormAdd({
			...formAdd,
			pageLocation: value,
		})
	}
	const onChangeIsActive = (value) => {
		setFormAdd({
			...formAdd,
			isActive: value,
		})
	}
	const onChangeDestinationTarget = (value) => {
		setFormAdd({
			...formAdd,
			destinationTarget: value,
		})
	}
	const handleChooseImage = () => {
		setBanner({
			...banner,
			thumbnail: `https://gofiber.b-cdn.net/Admin/${imageChoose}`,
		})
		setIsModalChooseImage(false)
	}
	const handleOpenChooseImage = () => {
		getListImageBunny().then((res) => {
			if (res.HttpCode === 401) {
				setMediaList()
			} else {
				setMediaList(res)
			}
		})
		setIsModalChooseImage(true)
	}

	//* useEffects
	useEffect(() => {
		if (id) {
			getBanner(id)
		}
	}, [id])

	const breadCrumbTitle = id ? 'Sửa' : 'Thêm mới'

	//* log variables before rendering ::::

	return (
		<>
			<Spin spinning={isLoading}>
				<div className='page-content'>
					<BreadCrumb
						title={breadCrumbTitle}
						pageTitle='Banner'
						slug='/home-slide-banner'
					/>
					<div className=''>
						<Form onSubmit={onSave}>
							<Row>
								<Col lg={12}>
									<FormGroup rules={[{ required: true }]}>
										<Label
											className='mb-1'
											for='title'
										>
											Tiêu đề
										</Label>
										<Input
											id='title'
											name='title'
											placeholder='Title'
											type='title'
											defaultValue={formAdd?.title || ''}
											onChange={onInputChange}
										/>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col lg={12}>
									<FormGroup rules={[{ required: true }]}>
										<Label
											className='mb-1'
											for='title'
										>
											Trang đặt Banner
										</Label>

										<Select
											value={formAdd.pageLocation}
											style={{ width: '100%' }}
											defaultValue={pageLocationEnum[0].value}
											placeholder='Chọn trang đặt banner'
											onChange={onChangePageLocation}
										>
											{pageLocationEnum.map((item) => (
												<Option
													key={item.key}
													value={item.value}
												>
													{item.label}
												</Option>
											))}
										</Select>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col lg={12}>
									<FormGroup rules={[{ required: true }]}>
										<Label
											className='mb-1'
											for='title'
										>
											Đường dẫn đến trang đích
										</Label>
										<Input
											id='destinationHref'
											name='destinationHref'
											placeholder='Trang đích'
											type='link'
											defaultValue={formAdd?.destinationHref || ''}
											onChange={onInputChange}
										/>
									</FormGroup>
								</Col>
							</Row>
							<Col lg={12}>
								<FormGroup>
									<Label
										className='mb-1'
										for='destinationHref'
									>
										Kiểu mở trang đích
									</Label>
									<Select
										value={
											formAdd?.destinationTarget === '_blank'
												? 'Mở trong tab mới'
												: 'Mở cùng tab hiện tại'
										}
										style={{ width: '100%' }}
										onChange={onChangeDestinationTarget}
									>
										<Option
											label='Mở trong tab mới'
											key={1}
											value={'_blank'}
										>
											Mở tab mới
										</Option>
										w
										<Option
											label='Mở cùng tab hiện tại'
											key={-1}
											value={'_self'}
										>
											Mở cùng tab hiện tại
										</Option>
									</Select>
								</FormGroup>
							</Col>
							<Row>
								<Col lg={12}>
									<FormGroup rules={[{ required: true }]}>
										<Label
											className='mb-1'
											for='imgAlt'
										>
											Mô tả hình ảnh
										</Label>
										<Input
											id='imgAlt'
											name='imgAlt'
											placeholder='Mô tả hình ảnh'
											type='text'
											defaultValue={formAdd?.imgAlt || ''}
											onChange={onInputChange}
										/>
									</FormGroup>
								</Col>
							</Row>
							<Col lg={12}>
								<FormGroup>
									<Label
										className='mb-1'
										for='isActive'
									>
										Trạng thái
									</Label>
									<Select
										value={formAdd?.isActive}
										// key={formAdd.status}
										style={{ width: '100%' }}
										onChange={onChangeIsActive}
									>
										<Option
											label='Không hoạt động'
											key={1}
											value={false}
										>
											Không hoạt động
										</Option>
										w
										<Option
											label='Đang hoạt động'
											key={-1}
											value={true}
										>
											Đang hoạt động
										</Option>
									</Select>
								</FormGroup>
							</Col>
							<Col lg={12}>
								<FormGroup>
									<Row>
										{/* Desktop */}
										<Col
											lg={8}
											className='position-relative box-edit-image'
										>
											<Label
												className='mb-1'
												for='imgDeskSrc'
											>
												Hình ảnh Desktop
											</Label>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
												}}
											>
												<label
													className='custom-file-upload'
													style={{ height: '100%' }}
												>
													<Input
														id='imgDeskSrc'
														name='imgDeskSrc'
														placeholder='Image'
														accept='image/*'
														type='file'
														defaultValue={formAdd?.imgDeskSrc || ''}
														onChange={onInputChange}
													/>
													Thêm hình ảnh Desktop
												</label>
											</div>
										</Col>
										{/* Tablet */}
										<Col
											lg={8}
											className='position-relative box-edit-image'
										>
											<Label
												className='mb-1'
												for='thumb'
											>
												Hình ảnh Tablet
											</Label>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
												}}
											>
												<label
													className='custom-file-upload'
													style={{ height: '100%' }}
												>
													<Input
														id='imgTabSrc'
														name='imgTabSrc'
														placeholder='Image'
														accept='image/*'
														type='file'
														defaultValue={formAdd?.imgTabSrc || ''}
														onChange={onInputChange}
													/>
													Thêm hình ảnh Tablet
												</label>
											</div>
										</Col>
										{/* Mobile */}
										<Col
											lg={8}
											className='position-relative box-edit-image'
										>
											<Label
												className='mb-1'
												for='thumb'
											>
												Hình ảnh Mobile
											</Label>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
												}}
											>
												<label
													className='custom-file-upload'
													style={{ height: '100%' }}
												>
													<Input
														id='imgMobSrc'
														name='imgMobSrc'
														placeholder='Image'
														accept='image/*'
														type='file'
														defaultValue={formAdd?.imgMobSrc || ''}
														onChange={onInputChange}
													/>
													Thêm hình ảnh Moblie
												</label>
											</div>
										</Col>
									</Row>
									<Row>
										<Col
											lg={8}
											className='position-relative box-edit-image'
										>
											{formAdd?.imgDeskSrc &&
											formAdd?.imgDeskSrc !== '' ? (
												<>
													<img
														src={formAdd?.imgDeskSrc}
														alt={formAdd?.imgAlt}
														style={{ width: '100%' }}
													/>
												</>
											) : (
												<p>Chưa có dữ liệu</p>
											)}
										</Col>
										<Col
											lg={8}
											className='position-relative box-edit-image'
										>
											{formAdd?.imgTabSrc &&
											formAdd?.imgTabSrc !== '' ? (
												<>
													<img
														src={formAdd?.imgTabSrc}
														alt={formAdd?.imgAlt}
														style={{ width: '100%' }}
													/>
												</>
											) : (
												<p>Chưa có dữ liệu</p>
											)}
										</Col>
										<Col
											lg={8}
											className='position-relative box-edit-image'
										>
											{formAdd?.imgMobSrc &&
											formAdd?.imgMobSrc !== '' ? (
												<>
													<img
														src={formAdd?.imgMobSrc}
														alt={formAdd?.imgAlt}
														style={{ width: '100%' }}
													/>
												</>
											) : (
												<p>Chưa có dữ liệu</p>
											)}
										</Col>
									</Row>
								</FormGroup>
							</Col>
							<Row>
								<Col
									style={{ marginLeft: '10px', marginTop: '10px' }}
								>
									<Button
										style={{ marginRight: '10px' }}
										onClick={onBack}
									>
										Quay lại
									</Button>
									<Button
										type='primary'
										onClick={onSave}
									>
										Lưu
									</Button>
								</Col>
							</Row>
						</Form>
					</div>
				</div>
			</Spin>
		</>
	)
}

export default CreateEditBanner
